body {
  margin: 0px;
  padding: 0px;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}

button, input, select, textarea {
  font-family: $app-font-family;
}
