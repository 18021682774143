@import url(https://fonts.googleapis.com/css?family=Lato:400,700,900);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/**
 * Color and Icon sets used for components with themes.
 */
/**
 * Color and Icon sets used for components with themes.
 */
body {
  margin: 0px;
  padding: 0px;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased; }

button, input, select, textarea {
  font-family: "Lato", "Helvetica Neue", Arial, sans-serif; }

body {
  -webkit-font-smoothing: antialiased;
  color: rgba(0, 0, 0, 0.85);
  font-family: "Lato", "Helvetica Neue", Arial, sans-serif;
  font-size: 14px;
  text-rendering: optimizeLegibility; }

h1, .h1 {
  font-size: 24px;
  font-weight: 900;
  line-height: 32px; }

h2, .h2 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 26px; }

h3, .h3 {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 24px; }

h4, .h4 {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 22px; }

h5, .h5 {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 20px; }

h6, .h6 {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 18px; }

.hidden {
  display: none; }

.unselectable {
  -webkit-user-select: none;
  -moz-user-select: -moz-none;
  -o-user-select: none;
  -ms-user-select: none;
      user-select: none; }

.clearfix:before, .clearfix:after {
  content: "";
  display: table; }

.clearfix:after {
  clear: both; }

.text--warning {
  color: #FF7D00; }

.text--default {
  color: #335c6d; }

.text--error {
  color: #C7384F; }

.text--info {
  color: #1573E6; }

.text--new {
  color: #663399; }

.text--success {
  color: #50B848; }

.text--help {
  color: #FFAB00; }

.text--maintenance {
  color: #FF7D00; }

.text--tag {
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased; }

.text--bold {
  font-weight: bold; }

.text--italic {
  font-style: italic; }

.text--secondary {
  color: rgba(0, 0, 0, 0.6); }

.text--inactive {
  color: rgba(0, 0, 0, 0.2); }

.App-header-title {
  background-color: black;
  color: white;
  padding: 0.5em;
  font-size: 1.5em; }

